import * as React from "react";
import * as styles from "./about.module.scss";

// markup
const IndexPage = () => {
  return (
    <>
      <div className={styles.page}>
        <a href="/" className={styles.back}>
          &larr; Accueil
        </a>
        <h1> Contactez-moi</h1>
        <p>
          Voici l'adresse courriel pour me rejoindre :<br />
          <a
            className={styles.link}
            href="mailto:diane.saint-pierre@hotmail.com"
          >
            diane.saint-pierre@hotmail.com
          </a>
          <br />
          <br />
          Au plaisir
          <br />
          <em>Diane</em> <br />
        </p>
      </div>
    </>
  );
};

export default IndexPage;
